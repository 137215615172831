import React from 'react'
import sidenav from '../components/sidenav'

export default function About() {
    return (
        <>
            <section className='about-us-img'>
                <div className="container">
                <p className='about-us-p'>About Us</p>

                </div>
            </section>
        </>
    )
}
