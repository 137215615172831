import React from 'react'
import Accordion from '../components/Accordion'

export default function Services() {
    return (
        <>
            <div className="container">
                <Accordion/>
            </div>
        </>
    )
}
